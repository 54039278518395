.content-view {
    font-family: Arial, sans-serif;
  }
  
  .header, .footer {
    text-align: center;
    background-color: #f4f4f4;
    padding: 20px;
  }
  
  .navigation ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
  }
  
  .navigation li {
    margin: 0 10px;
  }
  
  .navigation a {
    text-decoration: none;
    color: #007bff;
  }
  
  .featured-recipes {
    background-color: #fffbea;
    padding: 20px;
    text-align: center;
  }
  
  .recipes-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .recipe {
    margin: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 300px;
  }
  
  .recipe-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .search-section {
    text-align: center;
    margin: 20px 0;
  }
  
  .footer {
    background-color: #333;
    color: white;
  }
  @media print {
    body * {
      visibility: hidden;
    }
    
    .printable, .printable * {
      visibility: visible;
    }
    
    .printable {
      position: absolute;
      left: 0;
      top: 0;
    }
  }